<template>
  <!-- <div id="nav"> -->
    <!-- <router-link to="/camera">Camera</router-link> |
    <router-link to="/camera-test">CameraTest</router-link> |
    <router-link to="/edit">Edit</router-link> | -->
  <!-- </div> -->
  <support
    v-if="!data.isSupport"
  />
  <router-view v-else />
</template>

<script>
import { reactive, useStore, onMounted } from "@/helper/vue.js";
import Support from "@/pages/Support"

export default {
  name: 'Home',
  components: {
    Support
  },
  setup() {
    const { state, commit, dispatch } = useStore();
    const data = reactive({
      isSupport: true
    });

    const methods = {};

    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      data.isSupport = false
    } else {
      data.isSupport = true
    }

    onMounted(() => {
      dispatch('init')
    });

    return {
      data,
			methods,
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
