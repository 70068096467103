import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {store} from './store'
import axios from 'axios'
import mitt from 'mitt'

import './styles/common.scss'

axios.defaults.baseURL = process.env.VUE_APP_API_URL || 'https://front.sdc21mystack.com'

const app = createApp(App)
app.config.globalProperties.axios = axios
app.config.globalProperties.emitter = mitt()
app.use(store).use(router).mount('#app')
