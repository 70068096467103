import {createStore} from 'vuex'

export const store = createStore({
  state: {
    country: 'global',
    countryOption: [
      {
        key: 'us',
        value: 'English (US)'
      },
      {
        key: 'eu',
        value: 'English (EU)'
      },
      {
        key: 'global',
        value: 'English (Global)'
      },
      {
        key: 'korea',
        value: 'Korean (Republic of Korea)'
      },
      {
        key: 'latin',
        value: 'English (Latin America)'
      },
      {
        key: 'brazil',
        value: 'English (Brazil)'
      },
      {
        key: 'turkey',
        value: 'English (Turkey)'
      }
    ],
    stickers: [],
    currentStickers: [],
    takeImage: null,
    userInfo: {
      userId: 0,
      userImage: null,
      stickers: [
        {
          id: '0-0',
          width: null,
          height: null,
          left: null,
          top: null,
          scaleX: null,
          scaleY: null,
          angle: null,
        },
      ]
    },
    nickname: '',
    message: '',
    inputInfo: {
      name: '',
      type: [],
      message: ''
    },
    profile: {}
  },
  mutations: {
    init(state, payload) {
      state.stickers = payload.stickers;
    },
    changeCountry(state, val) {
      state.country = val
    },
    setStickersInfo(state, payload) {
      state.userInfo.stickers = payload.stickersInfo;
      state.userInfo.userImage = payload.userImage;
      console.log("state.userInfo------------", state.userInfo);
    },
    updateName(state, val) {
      state.inputInfo.name = val;
    },
    updateMessage(state, val) {
      state.inputInfo.message = val;
    },
    updateType(state, val) {
      console.log(val)
      state.inputInfo.type = [];
      state.inputInfo.type = val;
    },
    addSticker(state, val) {
      state.currentStickers.push(Object.assign({}, {...val}))
    },
    removeSticker(state, index) {
      state.currentStickers.splice(index, 1)
    },
    clearSticker(state) {
      state.currentStickers = []
    },
    transformSticker(state, {index, sticker}) {
      state.currentStickers[index] = sticker
    },
    takeImage(state, payload) {
      state.takeImage = payload
    },
    registerProfile(state, payload) {
      state.profile = payload
    }
  },
  actions: {
    async init({commit}, payload) {
      const stickers = await import('./data-stickers.js');
      // console.log("stickers ------store", stickers.default);
      commit('init', {'stickers': stickers.default.stickers});
    },
    addSticker({commit}, sticker) {
      commit('addSticker', sticker)
    },
    removeSticker({commit}, index) {
      commit('removeSticker', index)
    },
    clearSticker({commit}) {
      commit('clearSticker')
    },
    transformSticker({commit}, payload) {
      commit('transformSticker', {...payload})
    },
    takeImage({commit}, payload) {
      commit('takeImage', payload)
    },
    registerProfile({commit}, payload) {
      commit('registerProfile', payload)
    }
    // getSelectedItem({ commit, rootState }, payload) {
    //   commit('getSelectedItem', {'dataName': payload.dataName, 'currId': payload.currId, 'contents': payload.contents, 'selectedItemIndex': payload.selectedItemIndex, 'lang': rootState.isLangKr ? 'KR' : 'ENG' });
    // },
  }
})
