import {createRouter, createWebHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'landing',
    component: () => import('@/pages/Landing.vue'),
    props: (route) => ({ query: route.query.view })
  },
  {
    path: '/create',
    name: 'create',
    component: () => import('@/pages/create.vue'),
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('@/pages/registration.vue')
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('@/pages/share.vue')
  },
  {
    path: '/landing-test',
    name: 'landing-test',
    component: () => import('@/test/LandingTest.vue')
  },
  {
    path: '/edit-test',
    name: 'edit-test',
    component: () => import('@/test/EditTest.vue')
  },
  {
    path: '/camera-test',
    name: 'camera-test',
    component: () => import('@/test/CameraTest.vue')
  },
  {
    path: '/camera',
    name: 'camera',
    component: () => import('@/pages/Camera.vue')
  },
  {
    path: '/edit',
    name: 'cameraEdit',
    component: () => import('@/pages/CameraEdit.vue')
  },
  {
    path: '/gotoportal',
    beforeEnter(to, from, next) {
      window.location.replace('https://www.samsungdeveloperconference.com/flow/samsung/sdc21/main/page/portal?utm_source=qrcode&utm_medium=stackimg_qr&utm_campaign=qrscan_20211007')
    }
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/pages/Support.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  },
  routes
})

export default router
