<template>
  <div class="support">
    <header>
      <nav class="nav">
        <router-link class="nav__logo"  to="/">
          <img src="@/assets/landing/logo@2x.png" alt="sdc21">
        </router-link>
      </nav>
    </header>
    <main>
      <div class="content">
        <img src="@/assets/support.jpg" alt="SDC21 Stack">
        <h1>This website doesn’t support internet Explorer.</h1>
        <p>We recommend the latest versions of <a href="https://www.google.com/chrome/browser/">Google Chrome</a>, <a href="https://www.mozilla.org/firefox/new/">Firefox</a> or <a href="https://www.microsoft.com/edge/">Microsoft Edge.</a></p>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'support',
}
</script>

<style lang="scss" scoped>
.support {
  display: flex;
  flex-direction: column;
  height: 100vh;
  header {
    display: flex;
    flex-direction: column;
  }
  main {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
  }
}

.nav {
  padding: 24rem 40rem;
  background-color: #000;
  @media only screen and (max-width: 600px) {
    padding: 24rem 32rem;
  }
  &__logo {
    display: block;
    font-family: 'SamsungSharpSans', sans-serif;
    font-weight: bold;
    font-size: 28rem;
    width: 64rem;
    height: 16rem;
    img {
      width: 100%;
    }
  }
}

main {
  img {
    width: 196rem;
    margin: 0 auto 62rem;
  }
  h1 {
    margin: 0 0 14rem;
    font-family: 'SamsungSharpSans', sans-serif;
    font-size: 40em;
    font-weight: bold;
    color: #000;
  }
  p {
    margin: 0 0 66rem;
    font-family: 'SamsungOne', sans-serif;
    font-size: 22em;
    font-weight: 600;
    color: #444;
    a {
      color: #00b3e3;
    }
  }
}
</style>